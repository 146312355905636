<slick-dialog #changePasswordDialogRef header="Enter a new password" [showHeader]="false" [top]="140" [width]="width" (onClose)="onDialogClose()">
    <div class="d-flex flex-column align-items-center">
        <h5 class="border-0 mb-3 text-center">Please enter a password with at least:</h5>
        <ul>
            <li>8 characters</li>
            <li>1 upper</li>
            <li>1 lower</li>
            <li>1 symbol or 1 number.</li>
        </ul>

        <div class="mb-2 w-100">
            <label>New Password</label>
            <input type="password" class="form-control" [(ngModel)]="newPassword" (keyup)="validateComplexity()" />
            <form-error *ngIf="isSubmitted && !newPassword">New password is required</form-error>
            <form-error *ngIf="showComplexityIsValid && !complexityIsValid">Password does not meet complexity requirements.</form-error>
        </div>

        <div class="mb-3 w-100">
            <label>Verify New Password</label>
            <input type="password" class="form-control" [(ngModel)]="verifyNewPassword" />
            <form-error *ngIf="isSubmitted && !verifyNewPassword">Verify password is required</form-error>
            <form-error *ngIf="isSubmitted && newPassword !== verifyNewPassword">Passwords do not match</form-error>
        </div>
    </div>

    <slick-dialog-footer>
        <button type="button" [style.width.px]="isMobile ? 140 : 200" class="btn btn-outline-primary" [slick-button-spinner]="spinnerStatus" (click)="onUpdatePassword()">Update Password</button>
        <button type="button" [style.width.px]="isMobile ? 100 : 200" *ngIf="showCancelButton" class="btn btn-outline-danger ms-3" (click)="onDialogClose()">Cancel</button>

    </slick-dialog-footer>
</slick-dialog>