import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from '@angular/forms';
import { FormErrorModule } from "../form-error";
import { ChangePasswordDialogComponent } from "./change-password-dialog.component";
import { SlickButtonSpinnerModule, SlickDialogModule } from "slick-components";

@NgModule({
	imports: [CommonModule,
		FormsModule,
		FormErrorModule,
		SlickButtonSpinnerModule, SlickDialogModule],
	declarations: [ChangePasswordDialogComponent],
	exports: [ChangePasswordDialogComponent]
})
export class ChangePasswordDialogModule { }

