import { Component, ViewChild } from '@angular/core';
import { SlickDialogComponent } from "slick-components";
import { HttpErrorDialogService } from "@services/utils/http-error-dialog.service";
import { HttpErrorResponse } from "@angular/common/http";

@Component({
	selector: 'http-error-dialog',
	template: `
<slick-dialog key='DIALOG_HTTP_ERROR' width='300' showHeader='false' #dialogRef (onClose)="closeDialog()">
	<h3 class='m-0 p-0 ps-1 border-bottom'>{{header}}</h3>
	<div class='ps-1'>
		<div style='font-size: 1.1em;' class='text-danger'>
			<span *ngIf='errorMessage'>{{errorMessage}}<br /></span>
			{{exceptionMessage}}
		</div>
		<div *ngIf='stackTrace && showStackTrace === false' style='font-size: .8rem;'><button class='btn btn-link m-0 p-0' (click)='showStackTrace = true;'><i class='far fa-plus-square'></i> Show Stack Trace</button> </div>
		<div *ngIf='stackTrace && showStackTrace === true' style='font-size: .8rem;' [innerHtml]='stackTrace'></div>
	</div>
	<slick-dialog-footer><button class='btn btn-danger' (click)='closeDialog()'>Close</button></slick-dialog-footer>
</slick-dialog>`
})
export class HttpErrorDialogComponent {
	@ViewChild("dialogRef", { static: true }) dialogRef: SlickDialogComponent;

	constructor() {
		HttpErrorDialogService.errorDialogComponentCallback = this;
	}

	isOpen: boolean = false;
	header: string;
	errorMessage: string;
	exceptionMessage: string;
	showStackTrace: boolean = false;
	stackTrace: string;

	showHttpError(errorRes: HttpErrorResponse) {
		if (this.isOpen)
			return;

		this.isOpen = true;

		this.showStackTrace = false;

		if (errorRes.status === 404) {
			this.header = "Page not found";
			this.errorMessage = null;
			this.exceptionMessage = errorRes.url;
			this.stackTrace = null;
		}
		else if (errorRes.status === 500) {
			this.header = "An error has occurred";
			const actualError = errorRes.error.innerException || errorRes.error;
			this.errorMessage = (actualError.message === 'An error has occurred.') ? '' : actualError.message;
			this.exceptionMessage = actualError.exceptionMessage;
			this.stackTrace = (!errorRes.error.stackTrace) ? "" : errorRes.error.stackTrace.replace(/(?:\r\n|\r|\n)/g, '<br />');
		}
		else {
			this.header = "An error has occurred";
			this.errorMessage = errorRes.message;
			this.exceptionMessage = JSON.stringify(errorRes.error);
		}

		this.dialogRef.showDialog();
	}

	closeDialog() {
		this.isOpen = false;
		this.dialogRef.hideDialog();
	}
}