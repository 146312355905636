import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { LoginService, SleepService } from "@services";
import { SlickDialogComponent } from "slick-components";

@Component({
	selector: "change-password-dialog",
	templateUrl: "change-password-dialog.component.html"
})

export class ChangePasswordDialogComponent implements OnInit {
	@Input() isMobile: boolean;
	@ViewChild("changePasswordDialogRef") changePasswordDialogRef: SlickDialogComponent;

	protected res: any;
	protected token: string;
	protected width: number;

	protected showCancelButton: boolean;

	protected spinnerStatus: string;
	protected isSubmitted: boolean = false;

	protected newPasswordRequired: boolean = false;
	protected newPasswordRequiredUserId: number;
	protected newPassword: string;
	protected verifyNewPassword: string;
	private showComplexityTimer: NodeJS.Timeout;
	protected showComplexityIsValid: boolean;
	protected complexityIsValid: boolean;

	constructor(private loginService: LoginService) {
	}

	async ngOnInit() {
		this.isMobile = (!!this.isMobile).toString() === 'true';

		this.width = (this.isMobile) ? 320 : 520;
	}

	async showDialog(showCancelButton: boolean = false): Promise<string> {
		this.spinnerStatus = "reset";
		this.isSubmitted = false;
		this.showCancelButton = showCancelButton;
		this.newPassword = null;
		this.verifyNewPassword = null;
		this.showComplexityIsValid = false;
		this.complexityIsValid = false;
		
		return new Promise<string>((res) => {
			this.res = res;

			this.changePasswordDialogRef.showDialog();
		})
	}

	async validateComplexity() {
		this.showComplexityIsValid = false;
		clearTimeout(this.showComplexityTimer);

		this.showComplexityTimer = setTimeout(async () => {
			this.complexityIsValid = await this.loginService.verifyPasswordComplexity(this.newPassword);
			this.showComplexityIsValid = true;
		}, 700);
	}

	private isValid(): boolean {
		if (!this.newPassword)
			return false;

		if (!this.verifyNewPassword)
			return false;

		if (this.newPassword !== this.verifyNewPassword)
			return false;

		return true;
	}

	protected async onUpdatePassword() {
		// Assume it's true until it's not
		this.complexityIsValid = true;
		this.isSubmitted = true;
		this.spinnerStatus = "spin";

		try {
			if (this.isValid() === false) {
				this.spinnerStatus = "error"
				return;
			}

			this.complexityIsValid = await this.loginService.verifyPasswordComplexity(this.newPassword);

			if (!this.complexityIsValid) {
				this.spinnerStatus = "error";
				return;
			}

			this.spinnerStatus = "ok";
			await SleepService.sleep(500);

			this.res(this.newPassword);
			this.changePasswordDialogRef.hideDialog();
		}
		catch {
			this.spinnerStatus = "error";
		}
	}

	onDialogClose() {
		this.changePasswordDialogRef.hideDialog();
		this.res(null);
	}
}
